<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="ssl" :perms="permissions"></Menu>
        </div>
        
        <div class="col-md-5 custom-col-wire ml-3" v-if="permissions.includes('ssl-add-cert')">
            <input type="text" placeholder="NODE" v-model="vnc_node">
            <input type="text" placeholder="VMID" v-model="vnc_vmid">
            <input type="text" placeholder="VM_NAME" v-model="vnc_vmname">
            <button class="btn btn-primary" @click="generateVNC">START VNC</button>



            <iframe src="https://hf03.euronodes.com/?console=lxc&novnc=1&node=prox116&resize=scale&vmid=1604" width="100%" height="100%"></iframe>
        </div>
      </div>
    </template>
   
    <api-modal
      :title="'Wire API'"
      :apiTitles="['GET IPs','Add IP','Remove IP']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel']"
      :apiPayload="[{},
      {
        _ip: '66.66.66.56'
      },
      {
        _ip: '66.66.66.56'
      }
      ]"
    ></api-modal>

  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
import apiModal from "../components/modals/apiModal.vue";
// import fetch from 'node-fetch';
export default {
  name: "SSL",
  components: {
    Menu,
    MenuTop,
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ip: "127.0.0.1",
      port: "",
      vnc_vmid: "",
      vnc_node: "",
      vnc_res: "",
      pve_token: "",
      current_ip: "",
      validation: false,
      a_token: localStorage.getItem("token"),
      message: {
        msg: "Server did not return any message"
      },
      data: [],
      show_alert: false,
      alert_type: "",
      search: "",
      start_loading: false,
      claims: null,
      roles: [],
      Certs: [],
      currentServer: [],
      permissions: []
    };
  },
  methods: {
    getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
  
        axios
        .get(this.basicUrl + "sslCertsGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.Certs = res;
        });
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }
          if(!this.permissions.includes('ssl-add-cert')){
        this.$router.push("/403")
      }
          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    generateVNC() {
      window.open("https://hf03.euronodes.com/?console=lxc&novnc=1&node=prox116&resize=scale&vmid=1604", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
    },
  },
  computed: {
   
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    else{
      this.getData();
      axios
        .get("https://rdapi.gnuhost.eu/vm/test/kvm")
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.pve_token = res;
          // document.cookie = "PVEAuthCookie=" +this.pve_token+";path=/;domain=gnuhost.tech";
          document.cookie = "PVEAuthCookie=" + this.pve_token + ";domain=gnuhost.tech"
        });
      
    }
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
